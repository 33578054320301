import { useCallback, useState, MouseEvent, FC, useEffect } from 'react'
import { SelectChangeEvent } from '@mui/material'
// Hooks
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTabData } from 'modules/analyser/hooks'
import { useUser } from 'modules/user/hooks'
import { useReduxSelector } from 'modules/core/hooks'
import { USER_ROLE } from 'modules/user/constants'
import { useUserCache } from 'modules/cache/hooks'
import { useOrganizationContext } from 'modules/organization/hooks'
// redux
import { selectActiveTab } from 'modules/layout/redux'
// Components
import {
  InputEdit,
  IconButton,
  ConfirmDialog,
  Icon,
  ConfirmDialogState,
} from 'modules/core/components'
import { OrganizationCreateModal } from 'modules/organization/components'
// Utils
import { changeLanguage } from 'i18next'
import { doesUserHaveRole } from 'modules/user/utils'
// Types
import { TabDto } from 'modules/user/types'
import { AppBarProps } from './AppBar.interface'
// services
import { TokenService } from 'services/Token.service'
// constants
import { routeNames } from 'modules/core/constants'
import { languages } from 'modules/core/i18n/constants'
import { USER_SETTINGS_OPTIONS } from 'modules/user/components/ProfileSettings/constants'
import { NONE_ORG_ID } from 'modules/organization/constants'

import {
  StyledAppBar,
  StyledToolbar,
  TabsContainer,
  ToolbarActionsContainer,
  UserContainer,
  UserAvatar,
  UserText,
  UserFullName,
  UserRole,
  Tab,
  Divider,
  CloseIconWrapper,
  EditIconWrapper,
  TabIcon,
  AddTabWrapper,
  AnimatedChevronIcon,
  UserAvatarWrapper,
  UserMenu,
  UserMenuItem,
  UserMenuText,
  LanguageWrapper,
  LanguagesList,
  LanguageIcon,
  UserTierRoleWrapper,
  UserTier,
  OrganizationWrapper,
  OrganizationOptionsSelector,
} from './AppBar.styled'
import { OptionsSelectorMenuItem, Tooltip } from 'modules/core/styled'

export const AppBar: FC<AppBarProps> = ({
  editable = false,
  tabs,
  addTab = () => {},
  removeTab = () => {},
  renameTab = () => {},
  clickTab = () => {},
}) => {
  // state
  const [editingTab, setEditingTab] = useState<string | null>(null)
  const [removableTab, setRemovableTab] = useState<TabDto | null>(null)
  const [confirmTabDeleteDialogOpen, setConfirmTabDeleteDialogOpen] =
    useState<boolean>(false)
  const [profileMenuAnchor, setProfileMenuAnchor] =
    useState<null | HTMLElement>(null)
  const [languageMenuOpened, setLanguageMenuOpened] = useState<boolean>(false)
  const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] =
    useState(false)
  // hooks
  const navigate = useNavigate()
  const { currentTabPath, rootPath } = useTabData()
  const user = useUser()
  const { activeOrganizationId, organizations, setOrganization } =
    useOrganizationContext()
  const { t } = useTranslation(['core', 'pages'])
  const activeTab = useReduxSelector(selectActiveTab)

  useEffect(() => {
    if (currentTabPath !== activeTab && editable) {
      clickTab(currentTabPath)
    }
  }, [currentTabPath, activeTab, clickTab, editable])

  const { data } = useUserCache({
    keys: Object.keys(USER_SETTINGS_OPTIONS),
  })

  // variables
  const isContextMenuOpen = Boolean(profileMenuAnchor)
  const isAdmin = doesUserHaveRole(user.data, [USER_ROLE.ADMIN, USER_ROLE.ROOT])
  const isAbleToCreateOrg = doesUserHaveRole(user.data, [
    USER_ROLE.ROOT,
    USER_ROLE.ADMIN,
    USER_ROLE.COACH,
  ])

  const isCustomLayout =
    data && USER_SETTINGS_OPTIONS.CUSTOM_LAYOUTS in data
      ? data[USER_SETTINGS_OPTIONS.CUSTOM_LAYOUTS]
      : true

  // callbacks
  const handleGoToTab = useCallback(
    (tabPath: string) => {
      navigate(`${rootPath}/${tabPath}`)
    },
    [navigate, rootPath]
  )

  const handleSaveTab = useCallback(
    (newTabName: string) => {
      if (editingTab) {
        const newPath = renameTab(editingTab, newTabName)
        setEditingTab(null)
        handleGoToTab(newPath ?? 'dashboard')
      }
    },
    [renameTab, editingTab, handleGoToTab]
  )

  const handleTabClick = useCallback(
    (tab: TabDto) => () => {
      if (editingTab !== tab.id) {
        if (tab.useFullPath && tab.fullPath) {
          navigate(tab.fullPath)
          return
        }

        handleGoToTab(tab.path)
        clickTab(tab.path)
      }
    },
    [editingTab, handleGoToTab, navigate, clickTab]
  )

  const handleRemoveTab = useCallback(
    (tab: TabDto) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setConfirmTabDeleteDialogOpen(true)
      setRemovableTab(tab)
    },
    []
  )

  const handleConfirmRemoveTab = useCallback(
    (result: ConfirmDialogState) => {
      setConfirmTabDeleteDialogOpen(false)
      if (result === ConfirmDialogState.SUBMITTED && removableTab) {
        if (currentTabPath === removableTab.path) {
          handleGoToTab('dashboard')
        }
        removeTab(removableTab.id, currentTabPath === removableTab.path)
      }
    },
    [currentTabPath, removeTab, handleGoToTab, removableTab]
  )

  const handleEditTab = useCallback(
    (tab: TabDto) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setEditingTab(tab.id)
    },
    []
  )

  const handleLogout = useCallback(
    () => TokenService.getInstance().logout(),
    []
  )

  const handleGoToProfile = useCallback(() => {
    navigate(`${routeNames.profile.path}/home`)
  }, [navigate])

  const handleGoToHelp = useCallback(() => {
    navigate(`${routeNames.help.path}/terms`)
  }, [navigate])

  const handleGoToAdmin = useCallback(() => {
    navigate(`${routeNames.admin.path}/users`)
  }, [navigate])

  const handleSetOrganization = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      const orgId = target.value as string
      const isPersonalWorkspace = orgId === NONE_ORG_ID

      setOrganization(isPersonalWorkspace ? undefined : orgId)
    },
    [setOrganization]
  )

  const handleContextOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setProfileMenuAnchor(event.currentTarget)
  }

  const handleLanguageMenuOpen = () => setLanguageMenuOpened(true)
  const handleLanguageMenuClose = () => setLanguageMenuOpened(false)

  return (
    <>
      <StyledAppBar position='sticky'>
        <StyledToolbar>
          <TabsContainer open={tabs.length !== 0}>
            {tabs.map(tab => (
              <Tab
                key={tab.id}
                clickable={editingTab !== tab.id}
                editable={!tab.static && editingTab !== tab.id && editable}
                active={currentTabPath === tab.path}
                onClick={handleTabClick(tab)}
              >
                <CloseIconWrapper
                  hidden={tab.static || editingTab === tab.id || !editable}
                  onClick={handleRemoveTab(tab)}
                >
                  <TabIcon name='close' />
                </CloseIconWrapper>
                <EditIconWrapper
                  hidden={tab.static || editingTab === tab.id || !editable}
                  onClick={handleEditTab(tab)}
                >
                  <TabIcon name='pencil' />
                </EditIconWrapper>
                {editingTab === tab.id ? (
                  <InputEdit
                    defaultValue={tab.name}
                    onAccept={handleSaveTab}
                    onCancel={() => setEditingTab(null)}
                  />
                ) : (
                  tab.name
                )}
              </Tab>
            ))}
            {!isCustomLayout && (
              <AddTabWrapper>
                <Divider />
                <IconButton name='plus' onClick={() => addTab('Title')} />
              </AddTabWrapper>
            )}
            <ConfirmDialog
              open={confirmTabDeleteDialogOpen}
              onResult={handleConfirmRemoveTab}
              options={{
                title: t('pages:analyser.dialog.removeTab.title'),
                description: t('pages:analyser.dialog.removeTab.question', {
                  tabName: removableTab?.name,
                }),
              }}
            />
          </TabsContainer>
          <ToolbarActionsContainer>
            <LanguageWrapper
              onMouseOver={handleLanguageMenuOpen}
              onMouseLeave={handleLanguageMenuClose}
            >
              {languageMenuOpened && (
                <LanguagesList>
                  {languages.map(lang => (
                    <Tooltip key={lang.languageCode} title={lang.displayName}>
                      <LanguageIcon
                        src={lang.iconSrc}
                        alt={lang.displayName}
                        onClick={() => changeLanguage(lang.languageCode)}
                      />
                    </Tooltip>
                  ))}
                </LanguagesList>
              )}
              <Icon name='languages' />
            </LanguageWrapper>
            <OrganizationWrapper>
              <OrganizationOptionsSelector
                value={activeOrganizationId ?? NONE_ORG_ID}
                onChange={handleSetOrganization}
              >
                <OptionsSelectorMenuItem key={NONE_ORG_ID} value={NONE_ORG_ID}>
                  {t('pages:organization.context.defaultWorkspace')}
                </OptionsSelectorMenuItem>
                {organizations.map(({ id, name }) => (
                  <OptionsSelectorMenuItem key={id} value={id}>
                    {name}
                  </OptionsSelectorMenuItem>
                ))}
                {isAbleToCreateOrg && (
                  <OptionsSelectorMenuItem
                    key='create-org-menu-item'
                    onClick={() => setIsCreateOrganizationDialogOpen(true)}
                  >
                    {t('pages:organization.context.createNewOrganization')}
                  </OptionsSelectorMenuItem>
                )}
              </OrganizationOptionsSelector>
            </OrganizationWrapper>
            <UserContainer
              hidden={!Boolean(user.data)}
              onClick={handleContextOpen}
            >
              <UserText>
                <UserFullName variant='h6'>
                  {user.data?.firstname} {user.data?.lastname}
                </UserFullName>
                <UserTierRoleWrapper>
                  <UserRole variant='body2'>
                    {user.data?.role.toLowerCase()}
                  </UserRole>
                  <div> - </div>
                  <UserTier variant='body2'>
                    {user.data?.feature_tier.toLowerCase()}
                  </UserTier>
                </UserTierRoleWrapper>
              </UserText>
              <UserAvatarWrapper>
                <UserAvatar>{user.data?.firstname[0]}</UserAvatar>
                <AnimatedChevronIcon
                  name='chevron-small-down'
                  chevronUp={!isContextMenuOpen}
                />
              </UserAvatarWrapper>
            </UserContainer>
            <UserMenu
              anchorEl={profileMenuAnchor}
              open={isContextMenuOpen}
              onClose={() => setProfileMenuAnchor(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <UserMenuItem onClick={handleGoToProfile}>
                <Icon name='profile' />
                <UserMenuText>{t('nav.userContext.profile')}</UserMenuText>
              </UserMenuItem>
              {isAdmin && (
                <UserMenuItem onClick={handleGoToAdmin}>
                  <Icon name='settings' />
                  <UserMenuText>{t('nav.userContext.admin')}</UserMenuText>
                </UserMenuItem>
              )}
              <UserMenuItem onClick={handleGoToHelp}>
                <Icon name='question-circle' />
                <UserMenuText>{t('nav.userContext.help')}</UserMenuText>
              </UserMenuItem>
              <UserMenuItem onClick={handleLogout}>
                <Icon name='logout' />
                <UserMenuText>{t('nav.userContext.logout')}</UserMenuText>
              </UserMenuItem>
            </UserMenu>
          </ToolbarActionsContainer>
        </StyledToolbar>
      </StyledAppBar>
      <OrganizationCreateModal
        open={isCreateOrganizationDialogOpen}
        onClose={() => setIsCreateOrganizationDialogOpen(false)}
      />
    </>
  )
}
