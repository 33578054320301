import { FC, ReactNode } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
// Hooks
import { useObservable } from 'modules/core/hooks'
import { useUser, useUserAccess } from 'modules/user/hooks'
// Components
import { LoaderContainer } from 'modules/core/components'
// Constants
import { routes } from 'modules/core/routes'
// Services
import { TokenService } from 'services/Token.service'
// Types
import { UserFeatureTier, UserRole } from 'modules/user/types'

interface IProtectedRoute {
  restricted?: boolean
  redirectPath?: string
  children: ReactNode
}

interface ProtectedGlobalRouteProps {
  allowedUserRoles?: UserRole[]
  allowedFeatureTiers?: UserFeatureTier[]
  redirectPath?: string
  children: ReactNode
}

const ProtectedRoute: FC<IProtectedRoute> = ({
  restricted = true,
  redirectPath = '/',
  children,
}) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const isAuthenticated = token !== null

  if (!isAuthenticated && restricted) {
    return <Navigate to={redirectPath} replace />
  }

  return <>{children}</>
}

const ProtectedGlobalRoute: FC<ProtectedGlobalRouteProps> = ({
  allowedUserRoles,
  allowedFeatureTiers,
  redirectPath = '/profile/dashboard',
  children,
}) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const isAuthenticated = token !== null

  const enabledRoles = !!allowedUserRoles?.length && isAuthenticated
  const enabledTiers = !!allowedFeatureTiers && isAuthenticated

  const enabled = enabledRoles || enabledTiers
  const user = useUser({ enabled })
  const { validateUserAccess } = useUserAccess()

  if (user.isLoading && enabled) {
    return (
      <LoaderContainer variant='full' isLoading={true}>
        <div />
      </LoaderContainer>
    )
  }

  if (
    !validateUserAccess({
      featureTiers: allowedFeatureTiers,
      roles: allowedUserRoles,
    })
  )
    return <Navigate to={redirectPath} replace />

  return <>{children}</>
}

export const Router = () => {
  return (
    <Routes>
      {routes.map(
        (
          {
            layoutPath,
            routes,
            allowedUserRoles,
            allowedFeatureTiers,
            layout: Layout,
          },
          index
        ) => (
          <Route
            key={index}
            path={layoutPath}
            element={
              <ProtectedGlobalRoute
                allowedUserRoles={allowedUserRoles}
                allowedFeatureTiers={allowedFeatureTiers}
              >
                <Routes>
                  {routes.map(
                    (
                      {
                        path,
                        element,
                        restricted,
                        layout: NestedLayout = Layout,
                      },
                      routeIndex
                    ) => (
                      <Route
                        key={routeIndex}
                        path={path}
                        element={
                          <NestedLayout>
                            <ProtectedRoute restricted={restricted}>
                              {element}
                            </ProtectedRoute>
                          </NestedLayout>
                        }
                      />
                    )
                  )}
                </Routes>
              </ProtectedGlobalRoute>
            }
          />
        )
      )}
    </Routes>
  )
}
