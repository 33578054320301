import { UserFeatureTier } from 'modules/user/types'

export const FEATURE_TIER: Record<
  Uppercase<UserFeatureTier>,
  UserFeatureTier
> = {
  LITE: 'lite',
  BASIC: 'basic',
  ADVANCED: 'advanced',
  PROFESSIONAL: 'professional',
}
