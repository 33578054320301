import { FOOTBALL_EVENTS } from './football'
import { VOLLEYBALL_EVENTS } from './volleyball'
import { AMERICAN_FOOTBALL_EVENTS } from './american-football'
import { eventMerge } from 'modules/event/utils/eventMerge'

export const EVENTS = eventMerge(
  FOOTBALL_EVENTS,
  VOLLEYBALL_EVENTS,
  AMERICAN_FOOTBALL_EVENTS
)
