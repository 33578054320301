// Hooks
import { Navigate, useSearchParams } from 'react-router-dom'
// Providers
import { PopupContextProvider } from 'modules/landing/contexts/PopupContext/PopupContext'
// Components
import { FlexColumn } from 'modules/landing/components/shared/Flex'
import { Header } from 'modules/landing/components/static/Header'
import { VideoSection } from 'modules/landing/components/static/VideoSection/VideoSection'
import { AboutSection } from 'modules/landing/components/static/AboutSection/AboutSection'
import { FeaturesSection } from 'modules/landing/components/static/FeaturesSection/FeaturesSection'
import { CardSection } from 'modules/landing/components/static/CardSection/CardSection'
// import { PricingSection } from 'modules/landing/components/static/PricingSection/PricingSection'
import { ContactUsSection } from 'modules/landing/components/static/ContactUsSection/ContactUsSection'
import { Footer } from 'modules/landing/components/static/Footer/Footer'

import 'modules/landing/fonts.css'
import 'modules/landing/landing.css'

export const Landing = () => {
  const [params] = useSearchParams()
  const code = params.get('code')

  if (code) return <Navigate to='/redirecting' state={{ code }} replace />

  return (
    <PopupContextProvider>
      <FlexColumn sx={{ width: '100vw' }}>
        <Header />
        <VideoSection />
        <AboutSection />
        <CardSection />
        <FeaturesSection />
        {/* <PricingSection /> */}
        <ContactUsSection />
        <Footer />
      </FlexColumn>
    </PopupContextProvider>
  )
}
