export const AMERICAN_FOOTBALL_EVENTS = {
  KICKOFF: 4000,
  SNAP: 4001,
  HANDOFF: 4002,
  PASS: 4003,
  RUN: 4004,
  CATCH: 4005,
  TACKLE: 4006,
  TOUCHDOWN: 4007,
  FIELD_GOAL: 4008,
  EXTRA_POINT: 4009,
  INTERCEPTION: 4010,
  FUMBLE: 4011,
  PUNT: 4012,
  SAFETY: 4013,
  TIMEOUT: 4014,
  EXTRA_POINT_ATTEMPT: 4015,
  TURNOVER_ON_DOWNS: 4016,
  PENALTY: 4017,
  CHALLENGE: 4018,
  OVERTIME_START_END: 4019,
  FIRST_DOWN: 4020,
  COMPLETION: 4021,
  INCOMPLETION: 4022,
  SACK: 4023,
  HURRY: 4024,
  SCRAMBLE: 4025,
  SPIKE: 4026,
  FORCED_FUMBLE: 4027,
  PASS_DEFLECTION: 4028,
  PICK_SIX: 4029,
  BLOCKED_KICK: 4030,
  PUNT_RETURN: 4031,
  KICKOFF_RETURN: 4032,
  FAIR_CATCH: 4033,
  ONSIDE_KICK: 4034,
  MISSED_FIELD_GOAL: 4035,
  RED_ZONE_ENTRY: 4036,
  TWO_MINUTE_WARNING: 4037,
  GOAL_LINE_STAND: 4038,
  HAIL_MARY: 4039,
} as const
