import { User } from '@sporttotal-tv/dip-coaching-client'
import { UserFeatureTier } from 'modules/user/types'

export const isUserAllowedFeatureTier = (
  user: User | undefined,
  allowedTiers: UserFeatureTier[]
) => {
  if (!user) return false
  return allowedTiers.includes(user.feature_tier)
}
