import { UserRole } from 'modules/user/types'

export const USER_ROLE: Record<UserRole, UserRole> = {
  ROOT: 'ROOT',
  ADMIN: 'ADMIN',
  COACH: 'COACH',
  PLAYER: 'PLAYER',
  AGENT: 'AGENT',
}

export const nonSensitiveUserRoles: Record<
  Exclude<UserRole, 'ROOT'>,
  string
> = {
  ADMIN: 'admin',
  PLAYER: 'player',
  COACH: 'coach',
  AGENT: 'agent',
}

export const nonInternalUserRoles: Record<
  Exclude<UserRole, 'ROOT' | 'ADMIN'>,
  string
> = {
  PLAYER: 'player',
  COACH: 'coach',
  AGENT: 'agent',
}
