import { IconName } from 'modules/core/components/Icon/Icon.interface'
import { EVENTS } from 'modules/event/constants'
import { EventIndex } from 'modules/event/types'

/*
  Used to get corresponding icon name for specific event type id
 */
export const eventIndexToIconDictionary: Partial<
  Record<EventIndex | string, IconName>
> = {
  [EVENTS.GOAL]: 'goal',
  [EVENTS.YELLOW_CARD]: 'yellow-card',
  [EVENTS.RED_CARD]: 'red-card',
  [EVENTS.SUBSITUTE]: 'subsitute',
  [EVENTS.CORNER_BALL]: 'corner-ball',
  [EVENTS.OFFSIDE]: 'offside',
  [EVENTS.FREE_KICK]: 'free-kick',
  [EVENTS.PENALTY]: 'penalty',
  [EVENTS.FOUL]: 'foul',
  [EVENTS.SHOOT]: 'shoot',
  [EVENTS.SAVE]: 'goal-save',
  [EVENTS.DIRECT_KICK]: 'direct-kick',
  [EVENTS.INDIRECT_KICK]: 'indirect-kick',
  [EVENTS.ATT_THROW]: 'att-throw',
  [EVENTS.COUNTER_OFF]: 'counter-off',
  [EVENTS.CROSS]: 'cross',
  [EVENTS.SERVE]: 'serve',
  [EVENTS.SERVE_RETURN]: 'serve-return',
  [EVENTS.SERVE_ROTATION]: 'serve-rotation',
  [EVENTS.HIT_ON_THE_PITCH]: 'hit-in-pitch',
  [EVENTS.HIT_OUT_OF_THE_PITCH]: 'hit-out-pitch',
  [EVENTS.START_OF_1ST_SET]: 'set-start-1',
  [EVENTS.START_OF_2ND_SET]: 'set-start-2',
  [EVENTS.START_OF_3RD_SET]: 'set-start-3',
  [EVENTS.START_OF_4TH_SET]: 'set-start-4',
  [EVENTS.START_OF_5TH_SET]: 'set-start-5',
  [EVENTS.END_OF_1ST_SET]: 'set-end-1',
  [EVENTS.END_OF_2ND_SET]: 'set-end-2',
  [EVENTS.END_OF_3RD_SET]: 'set-end-3',
  [EVENTS.END_OF_4TH_SET]: 'set-end-4',
  [EVENTS.END_OF_5TH_SET]: 'set-end-5',
  [EVENTS.SCORE]: 'score',
  [EVENTS.SUBSITUTE]: 'subsitute',
  [EVENTS.HIGHLIGHT]: 'map-marker',
  [EVENTS.LIBERO_IN]: 'libero-in',
  [EVENTS.LIBERO_OUT]: 'libero-out',
  [EVENTS.LIBERO_SWITCH]: 'libero-switch',
  [EVENTS.START_OF_GAME]: 'game-start',
  [EVENTS.END_OF_GAME]: 'game-end',
  [EVENTS.CHANGE_SERVING]: 'change-serve',
  [EVENTS.NET_TOUCH_BY_THE_BALL]: 'ball-touch-net',
  [EVENTS.SERVE_FOOT_FOUL]: 'serve-foot-foul',
  [EVENTS.ONE_PASS_AND_HIT]: 'pass-and-hit',
  [EVENTS.BLOCK]: 'pass-and-block',
  [EVENTS.START_OF_HALF]: 'start-of-half',
  [EVENTS.END_OF_HALF]: 'end-of-half',
  [EVENTS.KICK_OFF]: 'kick-off',
  [EVENTS.GOAL_KICK]: 'goal-kick',
  [EVENTS.TRAINING_START]: 'training-start',
  [EVENTS.TRAINING_END]: 'training-end',

  // American Football Events
  [EVENTS.BLOCK_KICK]: 'block-kick',
  [EVENTS.CHALLENGE]: 'challenge',
  [EVENTS.EXTRA_POINT_ATTEMPT]: 'extra-point-attempt',
  [EVENTS.FAIR_CATCH]: 'fair-catch',
  [EVENTS.FIELD_GOAL]: 'field-goal',
  [EVENTS.FIRST_DOWN]: 'first-down',
  [EVENTS.FORCED_FUMBLE]: 'forced-fumble',
  [EVENTS.FUMBLE]: 'fumble',
  [EVENTS.GOAL_LINE_STAND]: 'goal-line-stand',
  [EVENTS.HAIL_MARY]: 'hail-mary',
  [EVENTS.HURRY]: 'hurry',
  [EVENTS.INTERCEPTION]: 'interception',
  [EVENTS.KICKOFF_RETURN]: 'kick-off-return',
  [EVENTS.KICKOFF]: 'american-football-kick-off',
  [EVENTS.MISSED_FIELD_GOAL]: 'missed-field-goal',
  [EVENTS.ONSIDE_KICK]: 'onside-kick',
  [EVENTS.OVERTIME_START_END]: 'overtime-start-end',
  [EVENTS.PASS_DEFLECTION]: 'pass-deflection',
  [EVENTS.PENALTY]: 'american-football-penalty',
  [EVENTS.PICK_SIX]: 'pick-six',
  [EVENTS.PUNT_RETURN]: 'punt-return',
  [EVENTS.PUNT]: 'punt',
  [EVENTS.RED_ZONE_ENTRY]: 'red-zone',
  [EVENTS.SACK]: 'sack',
  [EVENTS.SAFETY]: 'safety',
  [EVENTS.SCRAMBLE]: 'scramble',
  [EVENTS.SPIKE]: 'spike',
  [EVENTS.TACKLE]: 'tackle',
  [EVENTS.TIMEOUT]: 'timeout',
  [EVENTS.TOUCHDOWN]: 'touchdown',
  [EVENTS.TURNOVER_ON_DOWNS]: 'turnover-on-down',
  [EVENTS.TWO_MINUTE_WARNING]: 'two-minute-warning',
}
