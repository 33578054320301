import { ReactNode, useContext, useState } from 'react'
import { Alert, CssBaseline, Snackbar, styled } from '@mui/material'
import { SnackbarContext } from 'modules/core/context'
import { Nav } from 'modules/core/components'

interface ILayout {
  children: ReactNode
}

const AppContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Root = styled('div')`
  min-height: 100vh;
  display: flex;
`

export const LayoutProvider = ({ children }: ILayout) => {
  const { open, handleClose, snackbar } = useContext(SnackbarContext)
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <Root>
      <CssBaseline />
      <Nav
        isNavOpen={isNavOpen}
        toggleNavDrawer={() => setIsNavOpen(prev => !prev)}
      />
      <AppContainer>{children}</AppContainer>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.type}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Root>
  )
}
