import { ReactNode } from 'react'
// Layout
import { LayoutIndex } from 'modules/layout/constants'
// Components
import { Translation } from 'modules/core/components/Translation'
// Constants
import {
  assetsLayoutConfig,
  heatmapLayoutConfig,
  highlightsLayoutConfig,
  minimapLayoutConfig,
  playerControlsLayoutConfig,
  playerLayoutConfig,
  playlistLayoutConfig,
  statisticsLayoutConfig,
  telestrationLayoutConfig,
} from 'modules/layout/configs'
// Types
import { LayoutConfig } from 'modules/layout/types'
import { IconName } from 'modules/core/components/Icon/Icon.interface'
import { UserFeatureTier } from 'modules/user/types'
import { FEATURE_TIER } from 'modules/user/constants'

export type LayoutComponent = {
  icon: IconName
  name: NonNullable<ReactNode>
  layoutConfig: LayoutConfig & { type: LayoutIndex }
  allowedFeatureTier?: UserFeatureTier[]
}

export const LayoutIndexPaletteItem: Record<string, LayoutComponent> = {
  [LayoutIndex.PLAYER]: {
    icon: 'player',
    name: Translation('components:player.toolbar.title'),
    layoutConfig: playerLayoutConfig,
  },
  [LayoutIndex.PLAYER_CONTROLS]: {
    icon: 'controls',
    name: Translation('components:playerControls.toolbar.title'),
    layoutConfig: playerControlsLayoutConfig,
  },
  [LayoutIndex.ASSETS]: {
    icon: 'assets',
    name: Translation('components:asset.toolbar.title'),
    layoutConfig: assetsLayoutConfig,
  },
  [LayoutIndex.PLAYLIST]: {
    icon: 'playlist',
    name: Translation('components:playlist.toolbar.title'),
    layoutConfig: playlistLayoutConfig,
    allowedFeatureTier: [
      FEATURE_TIER.BASIC,
      FEATURE_TIER.ADVANCED,
      FEATURE_TIER.PROFESSIONAL,
    ],
  },
  [LayoutIndex.HIGHLIGHTS]: {
    icon: 'label',
    name: Translation('components:highlight.toolbar.title'),
    layoutConfig: highlightsLayoutConfig,
  },
  [LayoutIndex.TELESTRATION]: {
    icon: 'telestration',
    name: Translation('components:telestration.toolbar.title'),
    layoutConfig: telestrationLayoutConfig,
    allowedFeatureTier: [
      FEATURE_TIER.BASIC,
      FEATURE_TIER.ADVANCED,
      FEATURE_TIER.PROFESSIONAL,
    ],
  },
  [LayoutIndex.HEATMAP]: {
    icon: 'flame',
    name: Translation('components:heatmap.toolbar.title'),
    layoutConfig: heatmapLayoutConfig,
    allowedFeatureTier: [FEATURE_TIER.ADVANCED, FEATURE_TIER.PROFESSIONAL],
  },
  [LayoutIndex.MINIMAP]: {
    icon: 'minimap',
    name: Translation('components:minimap.toolbar.title'),
    layoutConfig: minimapLayoutConfig,
    allowedFeatureTier: [FEATURE_TIER.ADVANCED, FEATURE_TIER.PROFESSIONAL],
  },
  [LayoutIndex.STATISTICS]: {
    icon: 'statistics',
    name: Translation('components:statistics.toolbar.title'),
    layoutConfig: statisticsLayoutConfig,
    allowedFeatureTier: [FEATURE_TIER.ADVANCED, FEATURE_TIER.PROFESSIONAL],
  },
}
